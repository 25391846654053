.sidebarnav{
    z-index: 100;
    height: 100vh !important;
    background-color: #F5F7F9;
    position: fixed;
    top:0;
    left:0;
    width:350px;
    padding: 30px 20px;
    transition: all ease 0.4s ;
}

.sidebarnav li{
list-style: none;
height: 60px;
margin-top: 20px;
margin-bottom: 20px;
display:flex;
align-items: center;
border-radius: 10px;
white-space: nowrap;
font-family: 'Noto Sans';
}

.sidebarnav li.activeNavbar{
    background-color: #0095FF;
    color: #FFFFFF;
    cursor: pointer;
    transition: all ease 0.4s;
}

.sidebarnav li:hover{
    background-color: #0095FF;
    color: #FFFFFF;
    cursor: pointer;
}

.sidebarnav li .icon{
display:flex;
align-items: center;
justify-content: center;
font-size: 25px;
min-width: 60px;
}


.sidebarnav li{
    color:#131123;
}

.sidebarnav ul{
margin: 0%;
padding: 0%;
}

.sidebarnav.close{
    width:100px;
    transition: all ease 0.4s ;
}

.sidebarnav.close .image-text img{
    width:50px;
    transition: all ease 0.4s ;
    margin-bottom: 50px;
    display: flex;
    align-items: start;
}

.sidebarnav.close .text{
    opacity: 0;
    transition: all ease 0.1s ;
    white-space: nowrap;
}
.sidebarnav .text{
    font-family: 'Poppins-Regular';
    font-weight: 500;
    font-size: 16px;
}



.sidebarnav .image-text img{
    width:250px;
    transition: all ease 0.4s ;
    margin-bottom: 50px;
    display: flex;
    align-items: start;
}

.sidebarnav header .toggle{
    position: absolute;
    top:50px;
    transform: translateY(-50%);
    right:-10px;
    height:40px;
    width:40px;
    background: linear-gradient(90deg, #0095FF38, #0000C942); /* Define your linear gradient colors */
    border-radius: 50%;
    padding: 3px;
    transition: all ease 0.4s ;
    cursor: pointer;
}

.home{
    position: relative;
    height: 100vh;
    left:350px;
    width: calc(100% - 350px);
    transition: all ease 0.4s;
    z-index: 0;
}

.sidebarnav.close ~ .home{
    left:100px;
    width: calc(100% - 100px);
    transition: all ease 0.4s;
}

.sidebarnav.close .nav-link {
    position: relative;
    /* other styles... */
  }
  
.sidebarnav.close .tooltip {
    position: absolute;
    top: 60%;
    left: 110%;
    transform: translateY(-50%);
    padding: 8px;
    background-color: rgba(10, 10, 10, 0.6) ;
    color: #fff;
    border-radius: 4px;
    opacity: 0; /* Hide the tooltip initially */
    transition: opacity 0.3s ease;
    pointer-events: none; /* Allow interactions with elements behind the tooltip */
  }
  
.sidebarnav.close .nav-link:hover .tooltip {
    opacity: 1; /* Show the tooltip on hover */
  }