.header-container {
  display: flex;
  width:100%;
  background: #161616;
  flex-shrink: 0;
  height: 76px;
  align-items: center;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo {
  height: 21px;
  text-align: center;
}

.right-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.login {
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: -5%;
  cursor: pointer;
}

.login-icon {
  height: 21px;
  margin-right: 8px; 
}

.get-started-btn {
  background: #161616;
  color: #fff;
  border: 1px solid #525252;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 128px;
  height: 36px;
  border-radius: 6px;
}

@media (max-width: 1024px) {
  .header-container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 48px;
    padding-right: 48px;
    height: 76px;
  }
  .logo {
    height: 21px;
  }
  .right-container {
    gap: 25px;
  }
  .login {
    font-size: 14px;
    line-height: 16.42px;
  }
  .get-started-btn {
    font-size: 14px;
    line-height: 16.42px;
  }
}

@media (max-width: 834px) {
  .header-container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 48px;
    padding-right: 48px;
    height: 76px;
  }
  .logo {
    height: 21px;
  }
  .right-container {
    gap: 25px;
  }
  .login {
    font-size: 13px;
    line-height: 15.25px;
  }
  .get-started-btn {
    font-size: 13px;
    line-height: 15.25px;
  }
}

@media (max-width: 768px) {
  .header-container {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
  }
  .logo {
    height: 19px;
  }
  .right-container {
    gap: 25px;
  }
  .login {
    font-size: 13px;
    line-height: 15.25px;
  }
  .get-started-btn {
    font-size: 13px;
    line-height: 15.25px;
  }
}
/* Media query for screen size 375px */
@media (max-width: 430px) {
  .header-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 60px;
  }
  .logo {
    height: 16px;
  }
  .right-container {
    gap: 10px;
  }
  .login {
    font-size: 12px;
    line-height: 14px;
  }
  .get-started-btn {
   display:none
  }
}






.sec1 {
  height: 100%;
  background-color: black; 
  background-image: url('../Images/elegant-black-background%201.svg'); 
  background-repeat: no-repeat; 
  background-size: cover; 
  background-position: center; 
}

.sec1-title {
  font-family: "Bebas Neue", sans-serif;
  line-height: 96px;
  font-size: 92px;
  font-weight: 700;
  color: #fff;
  padding-top: 100px;
}

.sec1-highlight {
  color: #08b180;
}

.sec1-subtitle {
  font-family: "Work Sans", sans-serif;
  line-height: 22px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding-top: 30px ;
  letter-spacing: -0.050em;
}

.sec1-button-wrapper {
  margin-top: 50px;
}

.sec1-btn {
  background: #0a78ff;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 150px;
  height: 49px;
  border: none;
  border-radius: 8px;
}

.sec1-img {
  width: 100%;
}

@media (max-width: 1024px) {
  .sec1-title {
    line-height: 72px;
    font-size: 64px;
    padding-top:48px; 
    padding-left:86px;
    padding-right:86px;
  }
  .sec1-subtitle {
    line-height: 18.77px;
    font-size: 16px;
    padding-top: 20px;
  }
  .sec1-button-wrapper {
    margin-top: 15px;
  }
  
.sec1-btn {
  line-height: 16.42px;
  font-size: 14px;
  width: 100px;
  height: 39px;
}
}

@media (max-width: 834px) {
  .sec1-title {
    line-height: 56px;
    font-size: 56px;
    padding-top:48px; 
    padding-left:64px;
    padding-right:64px;
  }
  .sec1-subtitle {
    line-height: 17.06px;
    font-size: 15px;
    padding-top: 20px;
  }
  .sec1-button-wrapper {
    margin-top: 15px;
  }
  
.sec1-btn {
  line-height: 15.25px;
  font-size: 13px;
  width: 100px;
  height: 39px;
}
}

@media (max-width: 768px) {
  .sec1-title {
    line-height: 48px;
    font-size: 48px;
    padding-top:48px; 
    padding-left:64px;
    padding-right:64px;
  }
  .sec1-subtitle {
    line-height: 16.25px;
    font-size: 14px;
    padding-top: 20px;
  }
  .sec1-button-wrapper {
    margin-top: 15px;
  }
  
.sec1-btn {
  line-height: 15.25px;
  font-size: 13px;
  width: 100px;
  height: 39px;
}
}
/* Media query for screen size 375px */
@media (max-width: 430px) {
  .sec1-title {
    line-height: 36px;
    font-size: 36px;
    padding-top:24px; 
    padding-left:32px;
    padding-right:32px;
  }
  .sec1-subtitle {
    line-height: 14px;
    font-size: 12px;
    padding-top: 5px;
  }
  .sec1-button-wrapper {
    margin-top: 20px;
  }
  
.sec1-btn {
  line-height: 14px;
  font-size: 12px;
  width: 95px;
  height: 38px;
}
}







.sec2{
  background-color: #111111;
  background-image:url('../Images/photo2.svg');
  background-size:100% !important;
  background-repeat: no-repeat; 
  height: 100%;
  background-position: bottom;
  padding-bottom: 100px;
}

.sec2-headline {
  padding-top: 72px;
  font-family: 'Work Sans', sans-serif;
  line-height: 56px;
  font-size: 42px;
  font-weight: 400;
  color: #9d9d9d;
  letter-spacing: -0.075em;
}

.sec2-cta-button {
  display: inline-block;
  background: #0a78ff;
  font-family: 'Work Sans', sans-serif;
  line-height: 56px;
  font-size: 42px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.075em;
  margin: 0;
  padding: 0;
  border-radius: 8px;
}

.sec2-subtext {
  font-family: 'Work Sans', sans-serif;
  line-height: 26px;
  font-size: 18px;
  font-weight: 400;
  color: #FFF;
  letter-spacing: -0.075em;
  padding-top: 32px;
}

.sec2-video-container {
  display: flex;
  justify-content: center;
}

.sec2-video-wrapper {
  padding-top: 100px;
  width: 840px;
  height: 560px;
}

@media (max-width: 1024px) {
  .sec2{
    padding:56px 42px;
  }
  .sec2-headline {
    padding-top: 0px;
    line-height: 42px;
    font-size: 36px;
  }
  .sec2-cta-button {
    line-height: 42px;
    font-size: 36px;
  }
  .sec2-subtext {
    padding-top: 40px;
    line-height: 22px;
    font-size: 16px;
  }
  .sec2-video-wrapper {
    padding-top: 40px;
    width: 614px;
    height: 375px;
  }
}

@media (max-width: 834px) {
  .sec2{
    padding:56px 42px;
  }
  .sec2-headline {
    padding-top: 0px;
    line-height: 38px;
    font-size: 32px;
  }
  .sec2-cta-button {
    line-height: 38px;
    font-size: 32px;
  }
  .sec2-subtext {
    padding-top: 20px;
    line-height: 20px;
    font-size: 15px;
  }
  .sec2-video-wrapper {
    padding-top: 20px;
    width: 579px;
    height: 354px;
  }
}

@media (max-width: 768px) {
  .sec2{
    padding:56px 42px;
  }
  .sec2-headline {
    padding-top: 0px;
    line-height: 38px;
    font-size: 28px;
  }
  .sec2-cta-button {
    line-height: 38px;
    font-size: 28px;
  }
  .sec2-subtext {
    padding-top: 20px;
    line-height: 18px;
    font-size: 14px;
  }
  .sec2-video-wrapper {
    padding-top: 20px;
    width: 554px;
    height: 330px;
  }
}

@media (max-width: 430px) {
  .sec2{
    padding:32px 24px;
  }
  .sec2-headline {
    padding-top: 0px;
    line-height: 21px;
    font-size: 16px;
  }
  .sec2-cta-button {
    line-height: 21px;
    font-size: 16px;
  }
  .sec2-subtext {
    padding-top: 12px;
    line-height: 16px;
    font-size: 12px;
  }
  .sec2-video-wrapper {
    padding-top: 12px;
    width: 300px;
    height: 200px;
  }
}


.sec3{
  background-color: #111111;
  background-image:url('../Images/photo3.svg');
  background-size:100% !important;
  background-repeat: no-repeat; 
  background-position:top !important;
  padding-bottom: 100px;
  height: 100%;
}

.sec3-row {
  display: flex;
  justify-content: center;
  gap: 100px;
  padding-left: 24px;
  padding-right: 24px;
}

.sec3-image-container {
  display: flex;
  justify-content: center;
  width: 33.33%;
}

.sec3-image {
  padding-top: 130px;
  height: 800px;
}

.sec3-text-container {
  padding-top: 256px;
  text-align: left;
  width: 41.67%;
}

.sec3-title,
.sec3-subtitle {
  font-family: 'Bebas Neue', sans-serif;
  line-height: 64px;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}

.sec3-title {
  background: #0a78ff;
  display: inline-block;
  padding: 4px 0;
}

.sec3-highlight-text {
  display: inline-block;
  margin-top: 40px;
  color: #08b180;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.05em;
  padding: 0 4px;
}

.sec3-description {
  display: inline-block;
  margin-top: 40px;
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.05em;
}

.sec3-instruction {
  margin-top: 70px;
  color: #08b180;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.05em;
  display: inline-block;
  padding: 0 4px;
}

.sec3-steps {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-top: 30px;
  margin: 10px;
}

.sec3-card {
  background-color: #111111 !important;
  border: #232323 1px solid !important;
  border-radius: 24px !important;
  width: 500px !important;
  color:#ffffff !important;
  padding: 28px 24px;
  
}

.sec3-card-body{
 padding: 0px;
 margin: 0px;
 align-items: center;
 justify-content: center;
}

.sec3-card-row {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px; 
}

.sec3-icon {
  flex: 0 0 20%; /* Image container takes up only 20% of the row width */
  display: flex;
  justify-content: left;
  align-items: left;
}

.sec3-icon-image {
  width: 85px;
}

.sec3-step-details {
  flex: 1; /* Text container takes up the remaining space */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.sec3-step {
font-family: 'Work Sans';
font-size: 13px;
font-weight: 400;
line-height: 15.25px;
letter-spacing: 1.2999999523162842px;
text-align: left;
background-color: #080808;
padding: 6px 8px 6px 8px;
gap: 8px;
border-radius: 13.56px;
display: inline-flex;
justify-content: left !important;
}

.sec3-step-name {
font-family: 'Work Sans';
font-size: 42px;
font-weight: 400;
line-height: 48px;
letter-spacing: -0.075em;
text-align: left;
margin-top: 12px;
margin-bottom: 12px;
}

.sec3-divider {
  border: 1px solid #303030 !important;
  color:#303030 !important;
  width: 100%;
  margin: 0px;
}

.sec3-step-description {
font-family: 'Work Sans';
font-size: 18px;
font-weight: 400;
line-height: 25px;
letter-spacing: -0.05em;
text-align: left;
color:#9D9D9D;
padding-top: 13px;
}

.sec3-demo-button {
  background: #08b180;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 170px;
  height: 49px;
  border: none;
  border-radius: 8px;
  margin-top: 50px;
}


@media (max-width: 1024px) {

  .sec3{
   padding: 43px 32px;
  }

  .sec3-row {
    flex-direction: row;
    align-items: center;
    gap:0px;
    padding:0px;
  }

  .sec3-image-container {
    width: 100%;
    padding: 0; /* Removes padding for smaller screens */
    text-align: center;
  }

  .sec3-image {
    padding-top: 10px;
    height: auto;
    width: 100%; /* Scales image down to fit smaller screens */
  }

  .sec3-text-container {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: left;
  }

  .sec3-title,
  .sec3-subtitle {
    font-size: 48px;
    line-height: 48px;
  }

  .sec3-highlight-text{
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    padding: 0 10px; 
  }

  .sec3-description {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    padding: 0 0px; 
  }


  .sec3-instruction {
    margin-top: 30px;
    font-size: 16px;
    line-height: 22px;
    padding: 0 4px;
  }

  .sec3-steps {
    align-items: center;
    gap: 20px; 
  }

  .sec3-card {
    width: 300px !important; 
    padding: 20px 16px;
  }

  .sec3-card-row {
    gap: 20px; 
  }

  .sec3-icon-image {
    width: 38.5px;
  }

  .sec3-icon, 
  .sec3-step-details {
    width: 25%; 
  }

  .sec3-step{
    font-size: 12px;
    line-height: 14.08px;
    padding: 6px 8px 6px 8px;
    border-radius: 13.56px;
    justify-content: left !important;
    text-align: center;
  }

  .sec3-step-name{
    font-size: 36px;
    line-height: 36px;
    margin: 12px 0px;
  } 

  .sec3-step-description {
    font-size: 16px;
    line-height: 22px;
    text-align: left; 
    padding-top: 5px;
  }

  .sec3-demo-button {
    font-size: 16px;
    line-height: 18.77px;
    height: 45px;
    width:fit-content;
    margin-top: 15px;
  
  }

  .sec3-demo-button {
    margin-top: 40px;
    font-size: 16px;
    line-height: 18.77px;
  }

}

@media (max-width: 834px) {

  .sec3{
   padding: 43px 32px;
  }

  .sec3-row {
    flex-direction: row;
    align-items: center;
    gap:0px;
    padding:0px;
  }

  .sec3-image-container {
    width: 100%;
    padding: 0; /* Removes padding for smaller screens */
    text-align: center;
  }

  .sec3-image {
    padding-top: 10px;
    height: auto;
    width: 100%; /* Scales image down to fit smaller screens */
  }

  .sec3-text-container {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: left;
  }

  .sec3-title,
  .sec3-subtitle {
    font-size: 44px;
    line-height: 44px;
  }

  .sec3-highlight-text{
    font-size: 15px;
    line-height: 22px;
    margin-top: 20px;
    padding: 0 10px; 
  }

  .sec3-description {
    font-size: 15px;
    line-height: 22px;
    margin-top: 20px;
    padding: 0 0px; 
  }


  .sec3-instruction {
    margin-top: 30px;
    font-size: 15px;
    line-height: 22px;
    padding: 0 4px;
  }

  .sec3-steps {
    align-items: center;
    gap: 20px; 
  }

  .sec3-card {
    width: 300px !important; 
    padding: 20px 16px;
  }

  .sec3-card-row {
    gap: 20px; 
  }

  .sec3-icon-image {
    width: 38.5px;
  }

  .sec3-icon, 
  .sec3-step-details {
    width: 25%; 
  }

  .sec3-step{
    font-size: 12px;
    line-height: 14.08px;
    padding: 6px 8px 6px 8px;
    border-radius: 13.56px;
    justify-content: left !important;
    text-align: center;
  }

  .sec3-step-name{
    font-size: 32px;
    line-height: 36px;
    margin: 12px 0px;
  } 

  .sec3-step-description {
    font-size: 15px;
    line-height: 21px;
    text-align: left; 
    padding-top: 5px;
  }

  .sec3-demo-button {
    font-size: 16px;
    line-height: 18.77px;
    height: 45px;
    width:fit-content;
    margin-top: 15px;
  
  }

  .sec3-demo-button {
    margin-top: 40px;
    font-size: 16px;
    line-height: 18.77px;
  }

}


@media (max-width: 768px) {

  .sec3{
   padding: 48px 32px;
  }

  .sec3-row {
    flex-direction: column;
    align-items: center;
    gap:0px;
    padding:0px;
  }

  .sec3-image-container {
    order: 2; /* Places image after the text */
    width: 100%;
    padding: 0; /* Removes padding for smaller screens */
    text-align: center;
  }

  .sec3-image {
    padding-top: 10px;
    height: auto;
    width: 100%; /* Scales image down to fit smaller screens */
  }

  .sec3-text-container {
    order: 1;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: center;
  }

  .sec3-title,
  .sec3-subtitle {
    font-size: 36px;
    line-height: 36px;
  }

  .sec3-highlight-text,
  .sec3-description {
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    padding: 0 10px; 
  }

  .sec3-demo-button {
    width: 90%;
    margin-top: 40px;
    font-size: 14px;
    line-height: 16px;
  }

  .sec3-instruction {
    margin-top: 30px;
    font-size: 14px;
    line-height: 22px;
    padding: 0 4px;
  }

  .sec3-steps {
    align-items: center;
    gap: 20px; 
  }

  .sec3-card {
    width: 300px !important; 
    padding: 20px 16px;
  }

  .sec3-card-row {
    gap: 10px; 
  }

  .sec3-icon-image {
    width: 38.5px;
  }

  .sec3-icon, 
  .sec3-step-details {
    width: 25%; /* Ensures icon and details take full width */
  }

  .sec3-step{
    font-size: 10px;
    line-height: 12px;
    padding: 6px 8px 6px 8px;
    border-radius: 13.56px;
    justify-content: left !important;
    text-align: center;
  }

  .sec3-step-name{
    font-size: 26px;
    line-height: 36px;
    margin: 12px 0px;
  } 

  .sec3-step-description {
    font-size: 14px;
    line-height: 18px;
    text-align: left; 
    padding-top: 5px;
  }

  .sec3-demo-button {
    font-size: 13px;
    line-height: 15.25px;
    height: 45px;
    width:fit-content;
    margin-top: 15px;
  
  }
}



@media (max-width: 430px) {

  .sec3{
   padding-bottom: 50px;
  }

  .sec3-row {
    flex-direction: column;
    align-items: center;
    gap:0px;
  }

  .sec3-image-container {
    order: 2; /* Places image after the text */
    width: 100%;
    padding: 0; /* Removes padding for smaller screens */
    text-align: center;
  }

  .sec3-image {
    padding-top: 10px;
    height: auto;
    width: 100%; /* Scales image down to fit smaller screens */
  }

  .sec3-text-container {
    order: 1;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: center;
  }

  .sec3-title,
  .sec3-subtitle {
    font-size: 32px;
    line-height: 32px;
  }

  .sec3-highlight-text,
  .sec3-description {
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    padding: 0 10px; 
  }

  .sec3-instruction {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }

  .sec3-demo-button {
    width: 90%;
    margin-top: 40px;
    font-size: 14px;
    line-height: 16px;
  }

  .sec3-instruction {
    margin-top: 30px;
    font-size: 14px;
    line-height: 22px;
    padding: 0 4px;
  }

  .sec3-steps {
    flex-direction: column;
    align-items: center;
    gap: 20px; 
  }

  .sec3-card {
    width: 300px !important; 
    padding: 20px 16px;
  }

  .sec3-card-row {
    gap: 5px; 
  }

  .sec3-icon-image {
    width: 38.5px;
  }

  .sec3-icon, 
  .sec3-step-details {
    width: 25%; /* Ensures icon and details take full width */
  }

  .sec3-step{
    font-size: 10px;
    line-height: 12px;
    padding: 6px 8px 6px 8px;
    border-radius: 13.56px;
    justify-content: left !important;
    text-align: center;
  }

  .sec3-step-name{
    font-size: 26px;
    line-height: 36px;
    margin: 12px 0px;
  } 

  .sec3-step-description {
    font-size: 12px;
    line-height: 16px;
    text-align: left; 
    padding-top: 5px;
  }

  .sec3-demo-button {
    font-size: 13px;
    line-height: 15.25px;
    height: 45px;
    width:fit-content;
    margin-top: 15px;
  
  }
}







.sec4{
  background-color: #080808;
  background-size:100% !important;
  background-repeat: no-repeat; 
  background-position:top !important;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.sec4-header{
padding-left: 96px;
padding-right: 96px;
}


.sec4-title {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;


}

.sec4-highlight {
  background: #0A78FF;
  color: #fff;
  display: inline-block;
}

.sec4-subtitle {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #9D9D9D;
  padding-bottom: 100px;
}

.sec4-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.sec4-mask{
width: 100%;
padding: 0px 50px;
}


.sec4-column {
  text-align: left;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sec4-icon {
  height: 64px;
  width: 64px;
  margin-bottom: 24px;
}

.sec4-heading {
  font-family: 'Work Sans', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #fff;
  padding-bottom: 24px;
}

.sec4-description {
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #9D9D9D;
  padding-bottom: 24px;
}

.sec4-button {
  display: inline-block; /* Ensures button width matches content */
  color: #FFF;
  padding: 15px 16px; /* Provides necessary padding around text */
  font-family: 'Work Sans', sans-serif;
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #282828;
  border-radius: 8px;
  background-color: transparent;
  white-space: nowrap;
  width: 130px;
}

.sec4-gap {
  padding-top: 55px;
  display: flex;
  justify-content: center;
  gap: 100px;
}


@media (max-width: 1024px) {

  
  .sec4{
    padding: 50px 64px;
  }

  .sec4-title {
    font-size: 48px;
    line-height: 48px;
    display: inline-block;
  }
  .sec4-subtitle {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 48px;
    line-height: 48px;
    padding-bottom: 50px;
    padding-top: 10px;
  }
  .sec4-flex-container {
    align-items:start;    
    gap:50px;
  }

  .sec4-mask{
    height:380px;
    width: auto;
    padding: 0px 0px;
    }

  
  .sec4-column {
    padding-top: 40px;
    width: 100%;    
    text-align: left;
    align-items: left; /* Center-align children elements */
    justify-content: left; /* Center vertically if needed */
    width: 100%; /* Allow the column to take full width */
  }

  .sec4-gap {
    align-items:start;   
    padding-top: 50px;
    gap: 40px;
  }

  .sec4-icon {
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
  }

  .sec4-heading {
    text-align: left;
  }

  .sec4-heading {
    font-size: 26px;
    line-height: 28px;
    padding-bottom: 20px;
    text-align: left; 
  }
  
  .sec4-description {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 20px;
    text-align: left; 
  }

  .sec4-button {
    padding: 15px 16px; 
    line-height: 17.06px;
    font-size: 15px;
    border-radius: 8px;
  }

}

@media (max-width: 834px) {
  
  .sec4{
    padding:50px 64px;
  }

  .sec4-title {
    font-size: 44px;
    line-height: 44px;
    display: inline;
  }
  .sec4-subtitle {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 44px;
    line-height: 44px;
    padding-bottom: 50px;
  }
  .sec4-flex-container {
    align-items:start;    
    gap:50px;
  }

  .sec4-mask{
    height:350px;
    width: auto;
    padding: 0px 0px;
    }

  
  .sec4-column {
    padding-top: 0px;
    width: 100%;    
    text-align: left;
    align-items: left; /* Center-align children elements */
    justify-content: left; /* Center vertically if needed */
    width: 100%; /* Allow the column to take full width */
  }

  .sec4-gap {
    align-items:start;   
    padding-top: 20px;
    gap: 50px;
  }

  .sec4-icon {
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
  }

  .sec4-heading {
    text-align: left;
  }

  .sec4-heading {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 20px;
    text-align: left; 
  }
  
  .sec4-description {
    font-size: 15px;
    line-height: 21px;
    padding-bottom: 20px;
    text-align: left; 
  }

  .sec4-button {
    padding: 15px 16px; 
    line-height: 17.06px;
    font-size: 15px;
    border-radius: 8px;
  }

}

@media (max-width: 768px) {

  .sec4{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sec4-title {
    font-size: 36px;
    line-height: 36px;
    display: inline;
  }
  .sec4-subtitle {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 36px;
    line-height: 36px;
    padding-bottom: 50px;
  }
  .sec4-flex-container {
    flex-direction: column; 
    align-items: center;    
    gap:0px;
  }


  .sec4-column {
    width: 100%;    
    padding: 50px 96px;
    text-align: center;
    display: flex; /* Use flexbox to control alignment */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align children elements */
    justify-content: center; /* Center vertically if needed */
    width: 100%; /* Allow the column to take full width */
  }

  .sec4-gap {
    flex-direction: column; 
    align-items: center;  
    text-align: center; 
    padding-top: 30px;
    gap: 0px;
  }

  .sec4-icon {
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
  }

  .sec4-heading {
    text-align: center;
  }

  .sec4-heading {
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 20px;
    text-align: center; 
  }
  
  .sec4-description {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 20px;
    text-align: center; 
  }

  .sec4-button {
    padding: 15px 16px; 
    line-height: 15.25px;
    font-size: 13px;
    border-radius: 8px;
  }

}

@media (max-width: 430px) {

  .sec4{
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sec4-header{
    padding-left: 30px;
    padding-right: 30px;
    }

  .sec4-title {
    font-size: 32px;
    line-height: 32px;
    display: inline;
  }
  .sec4-subtitle {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 32px;
    line-height: 32px;
    padding-bottom: 50px;
  }
  .sec4-flex-container {
    flex-direction: column; 
    align-items: center;    
    gap:0px;
  }

  .sec4-mask{
    height:300px;
    width: auto;
    padding: 0px 0px;
    }

  .sec4-column {
    width: 100%;    
    padding: 30px 0px;
    text-align: center;
    display: flex; /* Use flexbox to control alignment */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align children elements */
    justify-content: center; /* Center vertically if needed */
    width: 100%; /* Allow the column to take full width */
  }

  .sec4-gap {
    flex-direction: column; 
    align-items: center;  
    text-align: center; 
    padding-top: 30px;
    gap: 0px;
  }

  .sec4-icon {
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
  }

  .sec4-heading {
    text-align: center;
  }

  .sec4-heading {
    font-size: 26px;
    line-height: 30px;
    padding-bottom: 20px;
    text-align: center; 
  }
  
  .sec4-description {
    font-size: 12px;
    line-height: 13px;
    padding-bottom: 20px;
    text-align: center; 
  }

  .sec4-button {
    padding: 15px 16px; 
    line-height: 15.25px;
    font-size: 13px;
    border-radius: 8px;
  }

}







.sec5{
  background-color: #000000;
  background-size:100% !important;
  background-image:url('../Images/glowRoad.svg');
  background-repeat: no-repeat; 
  height: 100%;
  padding-bottom: 100px;
}

.sec5-title {
  font-family: "Bebas Neue";
  font-size: 60px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 200px;
}

.highlight {
  background: #0A78FF;
  color: #fff;
  display: inline-block;
}

.sec5-card-container {
  padding: 40px 150px;
  display: flex;
  justify-content: center;
  gap: 32px;
  padding-top: 32px;
  flex-wrap: wrap;
  align-items: stretch; /* This will make all cards stretch to the height of the tallest card */
}


.cardType3{
  background-color: #111111 !important;
  border: #232323 1px solid !important;
  border-radius: 24px !important;
  width: 395px !important;
  padding: 32px !important;
  color:#ffffff !important;
}

.cardType3 .card-body{
  padding: 0px !important;
  }
  

.sec5 .card-body {
  text-align: left;
}

.sec5 .card-image {
  text-align: center;
  background-color: #0E0E0E;
  height: 165px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 17px;
  border: 1px solid #232323;
}

.sec5 .card-image-content {
  height: 100%;
}

.sec5 .card-title {
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  letter-spacing: -5%;
  margin-top: 34px;
  margin-bottom: 12px;
}

.sec5 .card-description {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #9D9D9D;
  letter-spacing: -5%;
}

@media (max-width: 1024px) {
  .sec5{
    padding-bottom: 50px;
  }

  .sec5-title {
    font-size: 48px;
    line-height: 48px;
    padding-top: 80px;
  }

  .sec5-card-container {
    padding: 30px 0px;
    display: flex;
    gap: 24px;
    flex-direction: row; /* Changed from column to row */
    justify-content: center;
    align-items: stretch; /* This will make all cards stretch to the height of the tallest card */
    flex-wrap: wrap; /* This allows cards to wrap to the next line if needed */
  }

  .cardType3{
    border-radius: 12px !important;
    width: 377px !important;
    padding: 26px !important;
    display: flex;
    flex-direction: column;
  }

  .sec5 .card-body {
    text-align: left;
  }

  .sec5 .card-image {
    border-radius: 6px;
  }
  .sec5 .card-title {
    font-size: 20px;
    line-height: 23.46px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  .sec5 .card-description {
    font-size: 16px;
    line-height: 22px;
  }

}


@media (max-width: 834px) {
  .sec5{
    padding-bottom: 50px;
  }

  .sec5-title {
    font-size: 44px;
    line-height: 44px;
    padding-top: 80px;
  }

  .sec5-card-container {
    display: flex;
    gap: 24px;
    flex-direction: row; /* Changed from column to row */
    justify-content: center;
    align-items: stretch; /* This will make all cards stretch to the height of the tallest card */
    flex-wrap: wrap; /* This allows cards to wrap to the next line if needed */
  }

  .cardType3{
    border-radius: 12px !important;
    width: 377px !important;
    padding: 26px !important;
    display: flex;
    flex-direction: column;
  }

  .sec5 .card-body {
    text-align: left;
  }

  .sec5 .card-image {
    border-radius: 6px;
  }
  .sec5 .card-title {
    font-size: 19px;
    line-height: 22.11px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  .sec5 .card-description {
    font-size: 15px;
    line-height: 21px;
  }

}

@media (max-width: 768px) {
  .sec5{
    padding-bottom: 50px;
  }
  .sec5-title {
    font-size: 36px;
    line-height: 36px;
    padding-top: 80px;
  }
  .sec5-card-container {
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cardType3{
    border-radius: 12px !important;
    width: 377px !important;
    padding: 26px !important;
  }

  .sec5 .card-body {
    text-align: center;
  }

  .sec5 .card-image {
    border-radius: 6px;
  }
  .sec5 .card-title {
    font-size: 18px;
    line-height: 21.11px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  .sec5 .card-description {
    font-size: 12px;
    line-height: 16px;
  }

}

@media (max-width: 430px) {
  .sec5{
    padding-bottom: 50px;
  }
  .sec5-title {
    font-size: 36px;
    line-height: 36px;
    padding-top: 100px;
  }
  .sec5-card-container {
    gap: 24px;
  }

  .cardType3{
    border-radius: 12px !important;
    width: 350px !important;
    padding: 26px !important;
  }

  .sec5 .card-body {
    text-align: center;
  }

  .sec5 .card-image {
    border-radius: 6px;
  }
  .sec5 .card-title {
    font-size: 18px;
    line-height: 21.11px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  
  .sec5 .card-description {
    font-size: 12px;
    line-height: 16px;
  }

}


.sec6{
  background-color: #000000;
  background-size:100% !important;
  background-repeat: no-repeat; 
  height: 100%;
  padding-top: 120px;
}

.sec6-container {
  display: flex;
  height: 575px;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #111111;
}

.sec6-image {
  margin-top: -120px;
  height: 700px;
}

.sec6-text {
  font-size: 42px;
  color: #fff;
  padding: 0 30px;
  text-align: left;
}

.sec6-title {
  text-align: left;
  font-family: "Work Sans";
  line-height: 56px;
  font-size: 42px;
  font-weight: 400;
  color: #9d9d9d;
  letter-spacing: -0.075em;
  margin-bottom: 20px;
}

.sec6-highlight {
  display: inline;
  text-align: left;
  background: #0a78ff;
  font-family: "Work Sans";
  line-height: 56px;
  font-size: 42px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.075em;
  padding: 0px 5px;
}

.sec6-subtitle {
  text-align: left;
  font-family: "Work Sans";
  line-height: 29px;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.050em;
  margin-top: 35px;
}

.sec6-getintouch{
    margin-top: 150px;
    margin-bottom: 60px;
    font-family: "Work Sans";
    line-height: 56px;
    font-size: 42px;
    font-weight: 400;
    color: #FFF;
    letter-spacing: -0.075em;
}


.sec6-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
}

.sec6-form-group {
  width: 600px;
  text-align: left;
}

.sec6-form-grouptext{
  text-align: left;
}

.sec6-form-label {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  letter-spacing: -5%;
}

.sec6-required {
  color: #08b180;
}

.sec6-inputInitialForm {
  width: 600px; 
}

.sec6-inputtextarea{
  width: 1250px;
  margin-top: 15px;
  border: solid 1px #252525;
  border-radius: 6px;
  background-color: #0D0D0D;
  padding: 19px 24px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
}

@media (max-width: 1024px) {  
  .sec6{
    padding-top: 50px;
  }
  .sec6-container {
    height: 408px;
  }
  .sec6-image {
    margin-top: 0px;
    height: 100%;
  }
  .sec6-text{
    text-align: left;
  }
  .sec6-title{
      line-height: 42px;
      font-size: 36px;
      text-align: left;
      margin-bottom: 0px;
  }
  .sec6-highlight{
      line-height: 42px;
      font-size: 36px;
      text-align: left;
  }
  .sec6-subtitle{
      line-height: 23.46px;
      font-size: 20px;
      text-align: left;
      margin-top: 20px;
  } 
  .sec6-getintouch{
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 42px;
    font-size: 36px;
  }
  }

@media (max-width: 834px) {  
  .sec6{
    padding-top: 50px;
  }
  .sec6-container {
    height: 345px;
  }
  .sec6-image {
    margin-top: 0px;
    height: 100%;
  }
  .sec6-text{
    text-align: left;
  }
  .sec6-title{
      line-height: 40px;
      font-size: 32px;
      text-align: left;
      margin-bottom: 0px;
  }
  .sec6-highlight{
      line-height: 40px;
      font-size: 32px;
      text-align: left;
  }
  .sec6-subtitle{
      line-height: 21.11px;
      font-size: 18px;
      text-align: left;
      margin-top: 20px;
  } 
  .sec6-getintouch{
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 42px;
    font-size: 36px;
  }
  }

@media (max-width: 768px) {  
  .sec6{
    padding-top: 0px;
  }
  .sec6-container {
      flex-direction: column; 
      align-items: center;
      height: auto;
  }
  .sec6-text {
      order: -1; 
      font-size: 28px;
      padding: 10px 20px;
      margin-top: 70px;
  }
  .sec6-image {
      order: 1; 
      margin-top: 60px;
      height: auto;
      width: 100%;
  }
  .sec6-text{
    text-align: center;
  }
  .sec6-title{
      line-height: 32px;
      font-size: 28px;
      text-align: center;
      margin-bottom: 0px;
  }
  .sec6-highlight{
      line-height: 38px;
      font-size: 28px;
      text-align: center;
  }
  .sec6-subtitle{
      line-height: 21.11px;
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
  } 
  .sec6-getintouch{
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 42px;
    font-size: 36px;
  }
  }

@media (max-width: 430px) {  
.sec6{
  padding-top: 60px;
}
.sec6-container {
    flex-direction: column; 
    align-items: center;
    height: auto;
}
.sec6-text {
    order: -1; 
    font-size: 24px;
    padding: 10px 20px;
    margin-top: 70px;
}
.sec6-image {
    order: 1; 
    margin-top: 60px;
    height: auto;
    width: 100%;
}
.sec6-text{
  text-align: center;
}
.sec6-title{
    line-height: 32px;
    font-size: 28px;
    text-align: center;
}
.sec6-highlight{
    line-height: 38px;
    font-size: 28px;
    text-align: center;
}
.sec6-subtitle{
    line-height: 21.11px;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
} 
.sec6-getintouch{
  margin-top: 50px;
  margin-bottom: 30px;
  line-height: 42px;
  font-size: 36px;
}
}


.sec6-submit-button {
  background: #08b180;
  color: #fff;
  font-family: "Work Sans";
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  height: 49px;
  width: 170px;
  border: none; /* Removes default border */
  border-radius: 4px; /* Optional: Adds rounded corners */
  transition: background 0.3s; /* Adds transition for hover effect */
}

.sec6-submit-button:disabled {
  background: #ccc; /* Gray background when disabled */
  cursor: not-allowed; /* Changes cursor to indicate it's not clickable */
}

.greenBackgroundTransperant{
  background-color: rgba(0, 169, 120, 0.22);
  border-radius: 4px;
}


.sec6-footer {
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 100px;
  font-family: "Work Sans"; /* Sets font for all children */
}

.sec6-footer-content {
  display: flex;
  align-items: center;
}

.sec6-logo-container {
  display: flex;
  align-items: center;
}

.sec6-logo {
  height: 21px;
}

.sec6-privacy-policy {
  margin-left: 20px; /* Space between the logo and Privacy Policy */
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #9d9d9d;
  padding-left: 100px; /* Space to the left of Privacy Policy */
  cursor: pointer;
}

.sec6-linkedin-container {
  margin-left: auto; /* Pushes LinkedIn logo to the right */
  text-align: right;
}



.sec6-divider {
  padding-left: 64px;
  padding-right: 64px;
  margin-top: 20px;
  color: "#9D9D9D";
  border: solid 1px;
}


.sec6-copyright {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9d9d9d;
  letter-spacing: -5%;
  padding-bottom: 40px;
}


@media (max-width: 1024px) {

  .sec6-form-container {
    padding: 0px 64px;
  }

  .sec6-form-group {
    width: 100%; 
  }

  .sec6-form-grouptext {
    width: 100%; 
  }

  .sec6-inputInitialForm {
    width: 100%; 
  }
  
  .sec6-inputtextarea{
    width: 100%;
  }

  .sec6-form-label {
    font-size: 16px;
    line-height: 24px;
  }

  .sec6-form-container {
    gap: 25px;
  }

  
.sec6-submit-button {
  line-height: 24px;
  font-size: 16px;
  height: 62px;
  width: 170px;
  padding: 19px 24px;
}


.sec6-footer {
  padding-left: 56px;
  padding-right: 56px;
  margin-top: 50px;
}

.sec6-logo {
  height: 18px;
}

.sec6-privacy-policy {
  font-size: 16px;
  line-height: 24px;
  padding-left: 50px;
}

.sec6-copyright {
  font-size: 14px;
  line-height: 24px;
}

}

@media (max-width: 834px) {

  .sec6-form-container {
    padding: 0px 64px;
  }

  .sec6-form-group {
    width: 100%; 
  }

  .sec6-form-grouptext {
    width: 100%; 
  }

  .sec6-inputInitialForm {
    width: 100%; 
  }
  
  .sec6-inputtextarea{
    width: 100%;
  }

  .sec6-form-label {
    font-size: 16px;
    line-height: 24px;
  }

  .sec6-form-container {
    gap: 16px;
  }

  
.sec6-submit-button {
  line-height: 24px;
  font-size: 16px;
  height: 62px;
  width: 170px;
  padding: 19px 24px;
}


.sec6-footer {
  padding-left: 56px;
  padding-right: 56px;
  margin-top: 50px;
}

.sec6-logo {
  height: 18px;
}

.sec6-privacy-policy {
  font-size: 15px;
  line-height: 24px;
  padding-left: 50px;
}

.sec6-copyright {
  font-size: 12px;
  line-height: 24px;
}

}


@media (max-width: 768px) {
  .sec6-form-container {
    flex-direction: column; 
    padding: 0px 80px;
  }

  .sec6-form-group {
    width: 100%; 
  }

  .sec6-form-grouptext {
    width: 100%; 
  }

  .sec6-inputInitialForm {
    width: 100%; 
  }
  
  .sec6-inputtextarea{
    width: 100%;
  }

  .sec6-form-label {
    font-size: 16px;
    line-height: 24px;
  }

  .sec6-form-container {
    gap: 15px;
  }

  
.sec6-submit-button {
  line-height: 24px;
  font-size: 16px;
  height: 62px;
  width: 170px;
  padding: 19px 24px;
}


.sec6-footer {
  padding-left: 56px;
  padding-right: 56px;
  margin-top: 50px;
}

.sec6-logo {
  height: 18px;
}

.sec6-privacy-policy {
  font-size: 15px;
  line-height: 24px;
  padding-left: 50px;
}

.sec6-copyright {
  font-size: 12px;
  line-height: 24px;
}

}

@media (max-width: 430px) {
  .sec6-form-container {
    flex-direction: column; 
    padding: 0px 27px;
  }

  .sec6-form-group {
    width: 100%; 
  }

  .sec6-form-grouptext {
    width: 100%; 
  }

  .sec6-inputInitialForm {
    width: 100%; 
  }
  
  .sec6-inputtextarea{
    width: 100%;
  }

  .sec6-form-label {
    font-size: 16px;
    line-height: 24px;
  }

  .sec6-form-container {
    gap: 15px;
  }

  
.sec6-submit-button {
  line-height: 24px;
  font-size: 16px;
  height: 62px;
  width: 170px;
  padding: 19px 24px;
}


.sec6-footer {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 50px;
}

.sec6-logo {
  height: 14px;
}

.sec6-privacy-policy {
  font-size: 15px;
  line-height: 24px;
  padding-left: 50px;
}

.sec6-copyright {
  font-size: 12px;
  line-height: 24px;
}

}










.notifyBar1{
  font-family: "Work Sans" !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 24px !important;
      color: #000 !important;
      letter-spacing: -5% !important;
      background: #fff !important;
}

.sec6-inputInitialForm, 
.sec6-inputInitialForm:focus, 
.sec6-inputInitialForm:active, 
.sec6-inputInitialForm::selection {
  margin-top: 15px !important;
  height: 62px !important;
  border: solid 1px #252525 !important;
  border-radius: 6px !important;
  background-color: #0d0d0d !important; 
  padding: 19px 24px !important;
  color: #fff !important; 
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  caret-color: #fff; 
}



.sec6-inputtextarea,
.sec6-inputtextarea:focus,
.sec6-inputtextarea:active,
.sec6-inputtextarea::selection
{
  margin-top: 15px !important;
  border: solid 1px #252525 !important;
  border-radius: 6px !important;
  background-color: #0d0d0d !important; 
  padding: 19px 24px !important;
  color: #fff !important; 
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  caret-color: #fff; 
}


input.sec6-inputInitialForm:-webkit-autofill, .sec6-inputtextarea:-webkit-autofill{
  -webkit-box-shadow: 0 0 0 1000px #0d0d0d inset !important;
  -webkit-text-fill-color: #fff !important; 
  font-size: 18px !important;
  font-weight: 400 !important;
  border: solid 1px #252525 !important;
  color: #fff !important; 
}


.sec6-inputInitialForm::selection{
  background-color: #08B180 !important;
  color: #fff !important; 
}

.sec6-inputInitialForm::-moz-selection{
  background-color: #08B180 !important;
  color: #fff !important; 
}

.sec6-inputtextarea::-moz-selection{
  background-color: #08B180 !important;
  color: #fff !important; 
}
.sec6-inputtextarea::selection {
  background-color: #08B180 !important; 
  color: #fff !important; 
}



input.sec6-inputInitialForm:-webkit-autofill:focus,  input.sec6-inputInitialForm:-webkit-autofill:hover, input.sec6-inputtextarea:-webkit-autofill:focus,  input.sec6-inputtextarea:-webkit-autofill:hover{
  background-color: #0d0d0d !important; 
  -webkit-box-shadow: 0 0 0 1000px #0d0d0d inset !important;
  -webkit-text-fill-color: #fff !important; 
}

input.sec6-inputInitialForm::placeholder {
  color: #aaa; 
  opacity: 1; 
}


.sec6-inputInitialForm:focus {
  outline: none; 
  border-color: #fff !important;
}
