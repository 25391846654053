body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("./CSS/Poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("./CSS/Poppins/Poppins-BlackItalic.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./CSS/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("./CSS/Poppins/Poppins-BoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./CSS/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("./CSS/Poppins/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("./CSS/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("./CSS/Poppins/Poppins-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("./CSS/Poppins/Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./CSS/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("./CSS/Poppins/Poppins-LightItalic.ttf");
}


@font-face {
  font-family: "Poppins-Medium";
  src: url("./CSS/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("./CSS/Poppins/Poppins-MediumItalic.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./CSS/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./CSS/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("./CSS/Poppins/Poppins-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./CSS/Poppins/Poppins-Thin.ttf");
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("./CSS/Poppins/Poppins-ThinItalic.ttf");
}

